import {useNavigate} from "react-router-dom";
import {IoIosHammer} from "react-icons/io";
import {IoAccessibility, IoAlarm} from "react-icons/io5";

export default function AiDocumentCheckerPresentation() {

    const navigate = useNavigate();
    const documentCheckerFeatures = [
        {
            icon: <IoIosHammer/>,
            title: "Feature 1",
            description: ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."],
            isDescription: true
        },
        {
            icon: <IoAccessibility/>,
            title: "Feature 2",
            description: ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."],
        },
        {
            icon: <IoIosHammer/>,
            title: "Feature 1",
            description: ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."],
            isDescription: true
        },
        {
            icon: <IoAccessibility/>,
            title: "Feature 2",
            description: ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."],
        }
    ];

    const techonoliges = [
        {
            icon: <IoAlarm className={"scale-150"}/>,
            text: "Python"
        },
        {
            icon: <IoAlarm className={"scale-150"}/>,
            text: "Machine Learning"
        },
        {
            icon: <IoAlarm className={"scale-150"}/>,
            text: "Artificial inteligence"
        },
        {
            icon: <IoAlarm className={"scale-150"}/>,
            text: "print()"
        },
        {
            icon: <IoAlarm className={"scale-150"}/>,
            text: "PLM"
        }
    ];

    const numbers = [
        {
            count: 150,
            display: "150+",
            category: "Firme sunt satisfacute"
        },
        {
            count: 30,
            display: "< 30",
            category: "secunde pentru analiza documentului"
        },
        {
            count: 0,
            display: "0",
            category: "Taxe pentru tine"
        },
        {
            count: 150,
            display: "150+",
            category: "Firme sunt satisfacute"
        },
        {
            count: 30,
            display: "< 30",
            category: "secunde pentru analiza documentului"
        },
        {
            count: 0,
            display: "0",
            category: "Taxe pentru tine"
        }
    ]

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section className={"p-10 bg-white h-[300px] md:h-[400px] flex justify-center items-end"}>
                <div className={"flex w-full max-w-[1150px] gap-10"}>
                    <h1 className={"font-main-bold text-3xl md:text-6xl pb-5 text-center md:text-start"}>
                        Inteligenta artificiala in procesarea
                        de document redefinita
                    </h1>
                </div>
            </section>
            <section className={"p-10 bg-white flex justify-center border-b-2 border-main-500"}>
                <div className={"flex w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full"}>
                        <p className={"font-main-medium text-md md:text-xl pb-5 text-center md:text-start"}>
                            DocuChecker este un produs nemapoimenit, de aceea are nevoie de un titlu mai lung
                        </p>
                        <p className={"text-center md:text-start"}>
                            Sau de o descriere mai complexta
                        </p>
                    </div>
                    <div className={"w-full hidden lg:block"}>
                        <img src={"https://clickup.com/blog/wp-content/uploads/2024/07/Frame-4-2.png"}
                             className={"rounded-xl object-cover w-full h-[400px]"} alt={""}/>
                    </div>
                </div>
            </section>
            <section className="py-10 h-fit bg-[#ffad33]">
                <div className={"px-8 relative z-10 md:px-32"}>
                    <p className="text-xl font-semibold text-white text-center w-full pt-5">
                        Ce inseamna sa folosesti <span className={"font-main-bold text-2xl"}>INOVIA</span>
                    </p>
                    <div className="flex flex-wrap justify-center items-center gap-5 mt-10">
                        {
                            numbers.map((el, index) => (
                                <div key={index}
                                     className="p-3 bg-white border-2 border-gray-100 rounded-lg shadow-lg w-full lg:w-[23%] hover:shadow-xl transition-shadow duration-300">
                                    <h2 className="text-6xl text-center w-full my-3 text-main-500 font-main-bold">{el.display}</h2>
                                    <p className="text-2xl text-gray-600 text-center">
                                        {el.category}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <section className={"p-10 flex justify-center"}>
                <div className={"flex flex-col w-full max-w-[1150px] gap-10"}>
                    <h1 className={"text-6xl font-main-bold"}>Piloni</h1>
                    <p className={""}>
                        From initial sketches to launch, we're here to understand your vision and craft a design that
                        aligns perfectly with your aspirations. Let's turn your business idea into a remarkable reality
                        that attracts and retains users.
                    </p>
                    <div className={"grid grid-cols-1 lg:grid-cols-3 gap-10"}>
                        <div className={"flex flex-col gap-5 bg-white rounded-xl border border-main-500 p-7 shadow-lg"}>
                            <IoAlarm className={"scale-150"}/>
                            <h1 className={"font-main-bold text-4xl"}>Scanat</h1>
                            <p>From initial sketches to launch, we're here to understand your vision and craft a design
                                that
                                aligns perfectly with your aspirations. Let's turn your business idea into a remarkable
                                reality
                                that attracts and retains users.</p>
                        </div>
                        <div className={"flex flex-col gap-5 bg-white rounded-xl border border-main-500 p-7 shadow-lg"}>
                            <IoAlarm className={"scale-150"}/>
                            <h1 className={"font-main-bold text-4xl"}>Educatie</h1>
                            <p>From initial sketches to launch, we're here to understand your vision and craft a design
                                that
                                aligns perfectly with your aspirations. Let's turn your business idea into a remarkable
                                reality
                                that attracts and retains users.</p>
                        </div>
                        <div className={"flex flex-col gap-5 bg-white rounded-xl border border-main-500 p-7 shadow-lg"}>
                            <IoAlarm className={"scale-150"}/>
                            <h1 className={"font-main-bold text-4xl"}>Automatizare stocare doc</h1>
                            <p>From initial sketches to launch, we're here to understand your vision and craft a design
                                that
                                aligns perfectly with your aspirations. Let's turn your business idea into a remarkable
                                reality
                                that attracts and retains users.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"py-20 px-10 flex justify-center"}>
                <div className={"flex flex-col lg:flex-row w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full lg:w-1/3"}>
                        <h1 className={"text-6xl font-main-bold"}>Technologii</h1>
                        <p className={"mt-10"}>
                            From initial sketches to launch, we're here to understand your vision and craft a design
                            that
                            aligns perfectly with your aspirations. Let's turn your business idea into a remarkable
                            reality
                            that attracts and retains users.
                        </p>
                    </div>
                    <div className={"h-fit w-full lg:w-2/3 flex items-start justify-start flex-wrap gap-5"}>
                        {
                            techonoliges.map((tech, i) =>
                                <div
                                    className={"flex flex-row justify-center h-fit items-center gap-5 bg-white rounded-full border p-6 shadow-lg"}>
                                    {tech.icon}
                                    <h1 className={"font-main-medium"}>{tech.text}</h1>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            <section className={"p-10 flex justify-center"}>
                <div className={"flex xl:flex-row flex-col w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full h-fit xl:sticky xl:top-[80px]"}>
                        <h1 className={"font-main-bold text-3xl md:text-6xl text-center md:text-start flex gap-2 xl:gap-7 flex-col pb-10"}>
                            <span>Ce este capabil </span>
                            <span className={"text-main-500 text-5xl"}>DocumentChecker</span>
                            <span>sa faca?</span>
                        </h1>
                        <div className={"flex flex-wrap xl:flex-col gap-2"}>
                            {
                                documentCheckerFeatures.map((el, i) =>
                                    <div className={"flex gap-5 text-2xl"}>
                                        {el.icon}
                                        <p className={""} key={i}>{el.title}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className={"w-full"}>
                        {
                            documentCheckerFeatures.map((el, i) =>
                                <div className={"bg-white w-full rounded-xl shadow-xl m-5 p-5"}>
                                    <h2 className={"text-center font-main-medium text-2xl"}>
                                        {el.title}
                                    </h2>
                                    {
                                        el.description.map((d, p) =>
                                            <p className={"text-justify text-sm"} key={p}>{d}</p>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};