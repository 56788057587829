import {IoIosHammer} from "react-icons/io";

export default function Home() {

    const numbers = [
        {
            count: 150,
            display: "150+",
            category: "Firme sunt satisfacute"
        },
        {
            count: 30,
            display: "< 30",
            category: "secunde pentru analiza documentului"
        },
        {
            count: 0,
            display: "0",
            category: "Taxe pentru tine"
        }
    ]

    const sectionDetails = [
        {
            id: "#first",
            category: "Category",
            title: "The first Feature we will have",
            description: "All the tools you need in one platform, boosting efficiency, enhancing visibility, and empowering everyone with data-driven decisions.",
            cards: [
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                }
            ]
        },
        {
            id: "#second",
            category: "Category",
            title: "The first Feature we will have",
            description: "All the tools you need in one platform, boosting efficiency, enhancing visibility, and empowering everyone with data-driven decisions.",
            cards: [
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                }
            ]
        },
        {
            id: "#third",
            category: "Category",
            title: "The first Feature we will have",
            description: "All the tools you need in one platform, boosting efficiency, enhancing visibility, and empowering everyone with data-driven decisions.",
            cards: [
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                }
            ]
        }
    ]

    return (
        <div className={"bg-main-background"}>
            <section className="h-screen flex justify-center pt-[100px] bg-hero-pattern bg-cover">
                <div className="px-20 w-full max-w-[950px] justify-center flex flex-col items-start pb-10">
                    <h1 className={"w-full text-xl sm:text-4xl md:text-6xl font-main-bold pb-3 text-center"}>
                        Inovație în automatizare prin <span className={"text-main-500"}>inteligență artificială</span>
                    </h1>
                    <h3 className={"text-sm sm:text-lg md:text-xl text-gray-700 text-center"}>
                        Prima companie de dezvoltare software care are ca scop ușurarea proceselor repetitive din cadrul
                        companiilor în industra de transporturi </h3>
                </div>
            </section>

            <section className="pb-10 h-fit lg:h-[400px]">
                <div className={"absolute z-0 w-full bg-[#ffad33] border-b-2 h-[200px]"}/>
                <div className={"px-8 relative z-10 md:px-32"}>
                    <p className="text-xl font-semibold text-white text-center w-full pt-5">
                        Ce inseamna sa folosesti <span className={"font-main-bold text-2xl"}>INOVIA</span>
                    </p>
                    <div className="flex flex-wrap justify-center items-center gap-5 mt-10">
                        {
                            numbers.map((el, index) => (
                                <div key={index}
                                     className="p-3 bg-white border-2 border-gray-100 rounded-lg shadow-lg w-full lg:w-[23%] hover:shadow-xl transition-shadow duration-300">
                                    <h2 className="text-6xl text-center w-full my-3 text-main-500 font-main-bold">{el.display}</h2>
                                    <p className="text-2xl text-gray-600 text-center">
                                        {el.category}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className={"flex flex-col lg:flex-row gap-10 px-8 md:px-32 pb-8"}>
                <div className={"w-full lg:w-3/4 flex gap-44 flex-col"}>
                    {
                        sectionDetails.map((el, index) =>
                                <div id={el.id.replace("#", "")} className={"p-2"} key={index}>
                        <span
                            className="bg-main-500 text-white text-xs font-medium me-2 px-2 py-1 rounded-lg">{el.category}</span>
                                    <h1 className={"text-3xl font-main-bold mt-3"}>{el.title}</h1>
                                    <p className={"text-lg text-gray-900"}>{el.description}</p>
                                    <div className="flex flex-wrap justify-start items-center gap-5 mt-10">
                                        {
                                            el.cards.map((card, i) =>
                                                <div
                                                    key={i}
                                                    className="bg-white border-main-500 border-2 rounded-lg shadow-lg w-full lg:w-1/4 hover:shadow-xl transition-shadow duration-300">
                                                    <div className="bg-main-500 px-4 pt-4 w-full rounded-t-md">
                                                        <img src={card.imagePath}
                                                             className={"h-48 w-full object-cover rounded-t-md"}
                                                             alt={""}/>
                                                    </div>
                                                    <h2 className="text-xl text-center px-4 w-full mt-3 text-gray-900 font-main-bold">
                                                        {card.cardsTitle}
                                                    </h2>
                                                    <p className="text-base text-gray-600 px-4 pb-4">
                                                        {
                                                            card.cardsDescription
                                                        }
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                        )
                    }
                </div>
                <div className={"hidden lg:flex w-1/4"}>
                    <div className={"h-fit p-2 sticky top-[100px] rounded-lg bg-main-100 flex flex-col"}>
                        {
                            sectionDetails.map((el) =>
                                <a href={el.id}
                                   className={"font-bold p-2 hover:bg-main-500 hover:text-white rounded-md flex gap-2 items-center justify-start"}>
                                    <IoIosHammer/> {el.title}
                                </a>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}