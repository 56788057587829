export default class MainAppRouter {
    // === HOME ===
    static home = "/";

    // === BLOG ===
    static blog = "/blog";
    static blogArticle = "/blog/:articleSlug";

    // === APPS ===
    static documentCheckerAi = "/verificare-documente";

    // === DETAILS ===
    static contact = "/contact";
    static pricing = "/pricing";

    // === LEGAL ===
    static termsAndConditions = "/terms-and-conditions";
    static privacyPolicy = "/privacy-policy";
    static cookiePolicy = "/cookie-policy";


    static portal = "https://portal.inovia-trans.ro";
    static portalShop = "https://portal.inovia-trans.ro/dashboard/store";
}