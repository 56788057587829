import headerLinks from "../header/HeaderLinks";
import {IoArrowForward} from "react-icons/io5";
import MainAppRouter from "../../router/MainAppRouter";
import {HiOutlineLocationMarker, HiOutlineMail} from "react-icons/hi";
import {MdOutlineLocalPhone} from "react-icons/md";
import React, {useState} from "react";
import NTPLogo from 'ntp-logo-react';

export default function Footer() {
    const [foreignEmail, setForeignEmail] = useState("");

    const links = [
        {href: MainAppRouter.termsAndConditions, text: 'Termeni și condiții'},
        {href: MainAppRouter.privacyPolicy, text: 'Politică de confidențialitate'},
        {href: MainAppRouter.cookiePolicy, text: 'Politică cookie'},
        {href: 'https://anpc.ro/', text: 'ANPC'},
        {href: 'https://www.dataprotection.ro/', text: 'ANSPDCP'}
    ];

    return (
        <div className={"bg-black flex flex-col px-16 pt-8 text-white"}>
            <div className={"w-full flex flex-col md:flex-row gap-5 md:gap-10"}>
                <div className={"w-full flex flex-col"}>
                    <h1 className={"text-2xl lg:text-3xl font-main-bold"}>
                        Aboneaza-te la newsletter
                    </h1>
                    <div className="w-full mt-6 flex gap-2 items-end">
                        <input type="text"
                               onChange={(v) => setForeignEmail(v.target.value)}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                               placeholder="Introdu emailul tau"/>
                        <button onClick={() => console.log(foreignEmail)}
                                className={"bg-main-500 p-3 w-fit h-fit rounded-lg"}>
                            <IoArrowForward/>
                        </button>
                    </div>
                </div>
                <div className={"w-full"}>
                    <h1 className={"text-2xl lg:text-4xl font-main-bold"}>
                        Informații
                    </h1>
                    <ul className="space-y-2 pt-2">
                        {links.slice(0, 3).map((link, index) => (
                            <li key={index} className={"flex items-center gap-2"}>
                                <a href={link.href}
                                   className="px-1 relative inline-block after:content-[''] after:absolute after:w-full after:scale-x-0 after:h-0.5 after:bg-main-500 after:bottom-0 after:left-0 after:origin-bottom-right after:transition-transform after:duration-300 hover:after:scale-x-100 hover:after:origin-bottom-left">
                                    {link.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={"w-full"}>
                    <h1 className={"text-2xl lg:text-3xl font-main-bold"}>
                        Utile
                    </h1>
                    <ul className="space-y-2 pt-2">
                        {links.slice(3).map((link, index) => (
                            <li key={index} className={"flex items-center gap-2"}>
                                <a href={link.href}
                                   className="px-1 relative inline-block after:content-[''] after:absolute after:w-full after:scale-x-0 after:h-0.5 after:bg-main-500 after:bottom-0 after:left-0 after:origin-bottom-right after:transition-transform after:duration-300 hover:after:scale-x-100 hover:after:origin-bottom-left">
                                    {link.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <NTPLogo  color="#000000" version="vertical" secret="143128" />
                </div>
                <div className={"w-full flex flex-col gap-2"}>
                    <h1 className={"text-2xl lg:text-3xl font-main-bold"}>
                        Contact
                    </h1>
                    <div className={"flex gap-2 items-center justify-start"}>
                        <div className={"rounded-full bg-main-500 p-2"}>
                            <HiOutlineLocationMarker/>
                        </div>
                        <span>Calea Plevnei 145B, Bucuresti, Sector 6</span>
                    </div>
                    <div className={"flex gap-2 items-center justify-start"}>
                        <div className={"rounded-full bg-main-500 p-2"}>
                            <MdOutlineLocalPhone/>
                        </div>
                        <a href={"tel:+40785225446"}>(+40) 785 225 446</a>
                    </div>
                    <div className={"flex gap-2 items-center justify-start"}>
                        <div className={"rounded-full bg-main-500 p-2"}>
                            <HiOutlineMail/>
                        </div>
                        <a href={"mailTo:contact@inovia-trans.ro"}>contact@inovia-trans.ro</a>
                    </div>
                </div>
            </div>
            <div className={"hidden md:flex justify-center items-center gap-5 w-full pt-10"}>
                {
                    headerLinks.map((el, index) =>
                        <a key={index} href={el.link}
                           className="px-1 relative inline-block font-semibold text-xl after:content-[''] after:absolute after:w-full after:scale-x-0 after:h-1 after:bg-main-500 after:-bottom-1 after:left-0 after:origin-bottom-right after:transition-transform after:duration-300 hover:after:scale-x-100 hover:after:origin-bottom-left">
                            {el.text}
                        </a>
                    )
                }
            </div>
            <div className={"flex justify-center items-center gap-5 w-full pt-2 pb-4"}>
                Inovia © {new Date().getFullYear()}. Toate drepturile rezervate
            </div>
        </div>
    )
        ;
}