import MainAppRouter from "../../router/MainAppRouter";

let headerLinks = [
    {
        link: MainAppRouter.home,
        text: "Acasa"
    },
    {
        link: MainAppRouter.blog,
        text: "Blog"
    },
    {
        link: MainAppRouter.documentCheckerAi,
        text: "DocChecker"
    },
    {
        link: MainAppRouter.pricing,
        text: "Preturi"
    },
    {
        link: MainAppRouter.contact,
        text: "Contact"
    },
];

export default headerLinks;