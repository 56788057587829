import {useParams} from "react-router-dom";
import articles from "../../components/articles/articles";
import {FaRegUserCircle} from "react-icons/fa";
import {LuAlarmClock} from "react-icons/lu";

export default function BlogArticle() {

    const {articleSlug} = useParams();

    return (
        <div className={"bg-main-background min-h-screen"}>
            <div className={"pt-[60px] bg-white md:pt-0"}></div>
            <section className={"p-10 bg-white h-fit md:h-[600px] flex justify-center items-end"}>
                <div className={"flex flex-col md:flex-row w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full md:w-2/5"}>
                        <img src={"https://clickup.com/blog/wp-content/uploads/2024/07/Frame-4-2.png"}
                             className={"rounded-xl object-cover w-full h-[400px]"}/>
                    </div>
                    <div className={"w-full md:w-3/5 flex flex-col justify-center"}>
                        <span className={"px-3 py-1.5 w-fit font-main-medium bg-main-500 rounded-full text-white"}>
                            {
                                articles[articleSlug].tag
                            }
                        </span>
                        <h1 className={"font-main-medium text-6xl py-5"}>
                            {
                                articles[articleSlug].title
                            }
                        </h1>
                        <div className={"flex flex-col md:flex-row gap-2 md:gap-0 justify-start"}>
                            <div className={"flex gap-1.5 items-center px-0 md:px-10"}>
                                <FaRegUserCircle/>
                                <span>{articles[articleSlug].personName}</span>
                            </div>
                            <div className={"flex gap-2 items-center px-0 md:px-10 md:border-x-2 border-main-500"}>
                                <LuAlarmClock/>
                                <span>{articles[articleSlug].date}</span>
                            </div>
                            <div className={"flex gap-2 items-center px-0 md:px-10"}>
                                <LuAlarmClock/>
                                <span>{articles[articleSlug].estimate}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"p-10 flex justify-center"}>
                <div className={"flex flex-wrap gap-10 w-full max-w-[1150px]"}>
                    {
                        articles[articleSlug].paragraphs.map((p) =>
                            <p className={"text-justify"}>{p}</p>
                        )
                    }
                </div>
            </section>

        </div>
    );
};