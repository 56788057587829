export default function TermsAndConditions() {

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section
                className={"pb-20 text-center px-8 md:px-32 pt-[60px] md:pt-[90px] bg-white border-b border-main-500"}>
                <h1 className={"text-4xl py-5 font-main-bold"}>
                    Termeni si conditii
                </h1>
            </section>
            <section className={"p-10 text-justify flex flex-col gap-3"}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in ipsum eleifend, posuere massa eu,
                    tempus tellus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                    himenaeos. Vestibulum sagittis ipsum ut interdum faucibus. Donec accumsan lacus in porta lobortis.
                    Aenean non erat risus. Donec mollis, odio non ultrices dignissim, sapien augue blandit metus, in
                    vehicula ipsum turpis nec tellus. Nunc vel massa massa. Mauris egestas massa id metus pretium
                    commodo. Integer eleifend lectus a urna viverra dapibus. Orci varius natoque penatibus et magnis dis
                    parturient montes, nascetur ridiculus mus. Sed ullamcorper suscipit odio non bibendum. Morbi non
                    mauris lectus.</p>
                <p>Duis iaculis neque ut magna gravida sodales. Donec feugiat urna a varius tempus. Nulla porttitor
                    massa risus, ac eleifend eros faucibus ac. Morbi pulvinar id ligula fermentum fermentum. Cras tellus
                    sapien, ultricies non aliquam quis, rhoncus sed velit. Maecenas cursus vitae sem non ultricies.
                    Mauris consequat felis nunc, sit amet tempor lectus suscipit a. In tristique ultricies augue.
                    Suspendisse vestibulum metus orci, sed varius lorem condimentum a.</p>
                <p>Cras efficitur eleifend ligula, at fermentum odio semper convallis. Class aptent taciti sociosqu ad
                    litora torquent per conubia nostra, per inceptos himenaeos. Cras sagittis volutpat augue ut maximus.
                    Praesent vel nunc ultricies, molestie odio elementum, iaculis dolor. Nunc venenatis pulvinar elit ac
                    auctor. Donec id elit eget augue lobortis eleifend non ultricies urna. Aenean euismod at dui non
                    tincidunt. Phasellus non lacinia urna. Morbi euismod mi a ornare congue. Maecenas mollis interdum
                    iaculis.</p>
                <p>Suspendisse justo magna, consequat sit amet suscipit fermentum, dictum non erat. Duis justo erat,
                    cursus quis feugiat id, mattis a ante. Nunc eleifend quam feugiat ex tincidunt varius. In hac
                    habitasse platea dictumst. Phasellus vel ligula non ante tristique lacinia vel scelerisque est.
                    Donec ultrices condimentum tempor. Praesent facilisis tempus libero, vel euismod nisl efficitur
                    at.</p>
                <p>Suspendisse eu quam dui. Curabitur vitae vulputate nisi. Class aptent taciti sociosqu ad litora
                    torquent per conubia nostra, per inceptos himenaeos. Proin eget velit id turpis tristique ornare a
                    sed nunc. Ut elementum convallis elit, eu sollicitudin ex pulvinar sit amet. Aliquam vel justo quis
                    massa viverra semper. Nullam luctus ligula eget posuere laoreet. Vivamus accumsan, magna eu
                    fermentum euismod, sem leo faucibus enim, id porta purus ligula ac lectus. Ut eu justo non est
                    convallis sollicitudin nec quis nisi. Morbi bibendum tortor sit amet dolor auctor elementum. Sed
                    nisi ipsum, euismod ut feugiat id, ornare quis sapien. Pellentesque cursus ornare nibh sed
                    vestibulum. Suspendisse potenti.</p>
                <p>Proin quis nisl et massa sagittis finibus id quis libero. Phasellus lobortis sem et felis sagittis
                    congue. Maecenas auctor nisi massa, tincidunt pharetra odio sodales quis. Pellentesque sit amet ex
                    urna. Quisque euismod dui et nulla iaculis gravida. Proin commodo cursus augue, eget tincidunt enim
                    commodo et. Curabitur commodo purus sed sapien ultrices, sed faucibus nisi tristique. Aenean varius
                    magna sit amet tincidunt fermentum. Ut at dui ut tortor semper eleifend. Mauris commodo risus
                    libero, vestibulum varius diam auctor eget. Suspendisse eros tellus, tempus a purus at, cursus
                    laoreet mauris. Aliquam facilisis in neque vel aliquam. Donec vitae felis rhoncus, semper est a,
                    aliquam augue. Nulla facilisi.</p>
            </section>
        </div>
    );
}