const articles = {
    primulArticol: {
        slug: "primulArticol",
        date: "14 August 2024",
        tag: "Folosirea aplicatiei",
        image: "https://clickup.com/blog/wp-content/uploads/2024/08/office-relocation-checklists-blog-featured-image.png",
        title: "Ce este un AI?",
        estimate: "20 min",
        personName: "Rares Dragutu",
        paragraphs: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium quis nisi ut convallis. Curabitur nec imperdiet turpis. Etiam odio elit, tempor a luctus in, lacinia vitae ipsum. Nullam sodales tortor vel velit vehicula lacinia. Nam iaculis metus sit amet nunc accumsan, sit amet tempor tellus efficitur. Cras viverra urna sit amet sem dictum vestibulum. Duis sed orci sit amet libero mattis fermentum ac quis mi. Pellentesque feugiat rhoncus velit non placerat. Phasellus eget luctus nisi, nec ornare sem. Cras ultricies pellentesque elit sit amet tempor. Praesent tempor dapibus consectetur. Vestibulum rutrum auctor interdum. Nulla risus magna, sollicitudin ut lacus eu, congue auctor eros. Aliquam nec convallis metus. Nullam quis iaculis turpis. Aenean at nulla nec ex interdum viverra ac nec purus.",
            "Sed euismod eros dictum mollis ultricies. Vestibulum pretium non mi sit amet suscipit. Quisque at lacus arcu. Nunc luctus eros sit amet enim lobortis, a congue purus interdum. Proin posuere, nulla venenatis volutpat condimentum, mauris velit semper sem, at vulputate leo risus id metus. Quisque et nulla pretium, laoreet ex a, ornare est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
            "Nunc molestie arcu in varius blandit. Nunc porttitor lorem justo, ut aliquam risus fermentum dignissim. Nam felis lorem, rutrum vulputate aliquam ac, aliquam eu purus. Duis vitae auctor magna. In sed risus velit. Nullam molestie viverra rutrum. Aenean condimentum orci scelerisque diam suscipit, vitae rhoncus urna tempus. In condimentum tempor magna, ullamcorper condimentum mauris consequat scelerisque.",
            "Cras ac nisl ultrices, scelerisque tellus vitae, rhoncus diam. Duis rhoncus pulvinar lorem et vehicula. Nulla in eros turpis. Duis sagittis lorem in feugiat sollicitudin. Aliquam hendrerit nisi fringilla, dapibus ante et, imperdiet magna. Curabitur vestibulum imperdiet orci ut sagittis. Vivamus vehicula nisi eleifend, malesuada tellus quis, venenatis diam. Curabitur vel consequat velit, sit amet condimentum dui. Phasellus sed velit consectetur, dictum purus vitae, semper felis. Donec in viverra nisi. Cras pulvinar arcu ut velit iaculis porta. Nulla a ullamcorper tellus. Quisque tellus nisl, ullamcorper sit amet ornare sed, placerat commodo sapien.",
            "Quisque facilisis laoreet interdum. Nulla et consectetur ligula, ac tristique massa. Maecenas convallis pulvinar quam, in varius dui blandit a. Vestibulum rhoncus luctus tortor eu eleifend. Sed justo metus, laoreet vel convallis nec, pharetra eu diam. Duis sit amet elit quis est facilisis vulputate eu eu sem. Donec porta orci sed accumsan eleifend. Donec non nisi sed nulla faucibus ornare non ac mauris. In hac habitasse platea dictumst. Aliquam sit amet dui at felis feugiat ullamcorper at eget erat. Sed ut semper dolor. Nullam facilisis nibh sem, quis congue sapien accumsan sed."
        ]
    },
    aldoileaarticol: {
        slug: "aldoileaarticol",
        date: "14 August 2024",
        tag: "Folosirea aplicatiei",
        image: "https://clickup.com/blog/wp-content/uploads/2024/08/office-relocation-checklists-blog-featured-image.png",
        title: "Ce este un AI?",
        estimate: "20 min",
        personName: "Rares Dragutu",
        paragraphs: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium quis nisi ut convallis. Curabitur nec imperdiet turpis. Etiam odio elit, tempor a luctus in, lacinia vitae ipsum. Nullam sodales tortor vel velit vehicula lacinia. Nam iaculis metus sit amet nunc accumsan, sit amet tempor tellus efficitur. Cras viverra urna sit amet sem dictum vestibulum. Duis sed orci sit amet libero mattis fermentum ac quis mi. Pellentesque feugiat rhoncus velit non placerat. Phasellus eget luctus nisi, nec ornare sem. Cras ultricies pellentesque elit sit amet tempor. Praesent tempor dapibus consectetur. Vestibulum rutrum auctor interdum. Nulla risus magna, sollicitudin ut lacus eu, congue auctor eros. Aliquam nec convallis metus. Nullam quis iaculis turpis. Aenean at nulla nec ex interdum viverra ac nec purus.",
            "Sed euismod eros dictum mollis ultricies. Vestibulum pretium non mi sit amet suscipit. Quisque at lacus arcu. Nunc luctus eros sit amet enim lobortis, a congue purus interdum. Proin posuere, nulla venenatis volutpat condimentum, mauris velit semper sem, at vulputate leo risus id metus. Quisque et nulla pretium, laoreet ex a, ornare est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
            "Nunc molestie arcu in varius blandit. Nunc porttitor lorem justo, ut aliquam risus fermentum dignissim. Nam felis lorem, rutrum vulputate aliquam ac, aliquam eu purus. Duis vitae auctor magna. In sed risus velit. Nullam molestie viverra rutrum. Aenean condimentum orci scelerisque diam suscipit, vitae rhoncus urna tempus. In condimentum tempor magna, ullamcorper condimentum mauris consequat scelerisque.",
            "Cras ac nisl ultrices, scelerisque tellus vitae, rhoncus diam. Duis rhoncus pulvinar lorem et vehicula. Nulla in eros turpis. Duis sagittis lorem in feugiat sollicitudin. Aliquam hendrerit nisi fringilla, dapibus ante et, imperdiet magna. Curabitur vestibulum imperdiet orci ut sagittis. Vivamus vehicula nisi eleifend, malesuada tellus quis, venenatis diam. Curabitur vel consequat velit, sit amet condimentum dui. Phasellus sed velit consectetur, dictum purus vitae, semper felis. Donec in viverra nisi. Cras pulvinar arcu ut velit iaculis porta. Nulla a ullamcorper tellus. Quisque tellus nisl, ullamcorper sit amet ornare sed, placerat commodo sapien.",
            "Quisque facilisis laoreet interdum. Nulla et consectetur ligula, ac tristique massa. Maecenas convallis pulvinar quam, in varius dui blandit a. Vestibulum rhoncus luctus tortor eu eleifend. Sed justo metus, laoreet vel convallis nec, pharetra eu diam. Duis sit amet elit quis est facilisis vulputate eu eu sem. Donec porta orci sed accumsan eleifend. Donec non nisi sed nulla faucibus ornare non ac mauris. In hac habitasse platea dictumst. Aliquam sit amet dui at felis feugiat ullamcorper at eget erat. Sed ut semper dolor. Nullam facilisis nibh sem, quis congue sapien accumsan sed."
        ]
    },
    altreileaarticol: {
        slug: "altreileaarticol",
        date: "14 August 2024",
        tag: "Folosirea aplicatiei",
        image: "https://clickup.com/blog/wp-content/uploads/2024/08/office-relocation-checklists-blog-featured-image.png",
        title: "Ce este un AI?",
        estimate: "20 min",
        personName: "Rares Dragutu",
        paragraphs: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium quis nisi ut convallis. Curabitur nec imperdiet turpis. Etiam odio elit, tempor a luctus in, lacinia vitae ipsum. Nullam sodales tortor vel velit vehicula lacinia. Nam iaculis metus sit amet nunc accumsan, sit amet tempor tellus efficitur. Cras viverra urna sit amet sem dictum vestibulum. Duis sed orci sit amet libero mattis fermentum ac quis mi. Pellentesque feugiat rhoncus velit non placerat. Phasellus eget luctus nisi, nec ornare sem. Cras ultricies pellentesque elit sit amet tempor. Praesent tempor dapibus consectetur. Vestibulum rutrum auctor interdum. Nulla risus magna, sollicitudin ut lacus eu, congue auctor eros. Aliquam nec convallis metus. Nullam quis iaculis turpis. Aenean at nulla nec ex interdum viverra ac nec purus.",
            "Sed euismod eros dictum mollis ultricies. Vestibulum pretium non mi sit amet suscipit. Quisque at lacus arcu. Nunc luctus eros sit amet enim lobortis, a congue purus interdum. Proin posuere, nulla venenatis volutpat condimentum, mauris velit semper sem, at vulputate leo risus id metus. Quisque et nulla pretium, laoreet ex a, ornare est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
            "Nunc molestie arcu in varius blandit. Nunc porttitor lorem justo, ut aliquam risus fermentum dignissim. Nam felis lorem, rutrum vulputate aliquam ac, aliquam eu purus. Duis vitae auctor magna. In sed risus velit. Nullam molestie viverra rutrum. Aenean condimentum orci scelerisque diam suscipit, vitae rhoncus urna tempus. In condimentum tempor magna, ullamcorper condimentum mauris consequat scelerisque.",
            "Cras ac nisl ultrices, scelerisque tellus vitae, rhoncus diam. Duis rhoncus pulvinar lorem et vehicula. Nulla in eros turpis. Duis sagittis lorem in feugiat sollicitudin. Aliquam hendrerit nisi fringilla, dapibus ante et, imperdiet magna. Curabitur vestibulum imperdiet orci ut sagittis. Vivamus vehicula nisi eleifend, malesuada tellus quis, venenatis diam. Curabitur vel consequat velit, sit amet condimentum dui. Phasellus sed velit consectetur, dictum purus vitae, semper felis. Donec in viverra nisi. Cras pulvinar arcu ut velit iaculis porta. Nulla a ullamcorper tellus. Quisque tellus nisl, ullamcorper sit amet ornare sed, placerat commodo sapien.",
            "Quisque facilisis laoreet interdum. Nulla et consectetur ligula, ac tristique massa. Maecenas convallis pulvinar quam, in varius dui blandit a. Vestibulum rhoncus luctus tortor eu eleifend. Sed justo metus, laoreet vel convallis nec, pharetra eu diam. Duis sit amet elit quis est facilisis vulputate eu eu sem. Donec porta orci sed accumsan eleifend. Donec non nisi sed nulla faucibus ornare non ac mauris. In hac habitasse platea dictumst. Aliquam sit amet dui at felis feugiat ullamcorper at eget erat. Sed ut semper dolor. Nullam facilisis nibh sem, quis congue sapien accumsan sed."
        ]
    },
    alpatruleaarticol: {
        slug: "alpatruleaarticol",
        date: "14 August 2024",
        tag: "Folosirea aplicatiei",
        image: "https://clickup.com/blog/wp-content/uploads/2024/08/office-relocation-checklists-blog-featured-image.png",
        title: "Ce este un AI?",
        estimate: "20 min",
        personName: "Rares Dragutu",
        paragraphs: [
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium quis nisi ut convallis. Curabitur nec imperdiet turpis. Etiam odio elit, tempor a luctus in, lacinia vitae ipsum. Nullam sodales tortor vel velit vehicula lacinia. Nam iaculis metus sit amet nunc accumsan, sit amet tempor tellus efficitur. Cras viverra urna sit amet sem dictum vestibulum. Duis sed orci sit amet libero mattis fermentum ac quis mi. Pellentesque feugiat rhoncus velit non placerat. Phasellus eget luctus nisi, nec ornare sem. Cras ultricies pellentesque elit sit amet tempor. Praesent tempor dapibus consectetur. Vestibulum rutrum auctor interdum. Nulla risus magna, sollicitudin ut lacus eu, congue auctor eros. Aliquam nec convallis metus. Nullam quis iaculis turpis. Aenean at nulla nec ex interdum viverra ac nec purus.",
            "Sed euismod eros dictum mollis ultricies. Vestibulum pretium non mi sit amet suscipit. Quisque at lacus arcu. Nunc luctus eros sit amet enim lobortis, a congue purus interdum. Proin posuere, nulla venenatis volutpat condimentum, mauris velit semper sem, at vulputate leo risus id metus. Quisque et nulla pretium, laoreet ex a, ornare est. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.",
            "Nunc molestie arcu in varius blandit. Nunc porttitor lorem justo, ut aliquam risus fermentum dignissim. Nam felis lorem, rutrum vulputate aliquam ac, aliquam eu purus. Duis vitae auctor magna. In sed risus velit. Nullam molestie viverra rutrum. Aenean condimentum orci scelerisque diam suscipit, vitae rhoncus urna tempus. In condimentum tempor magna, ullamcorper condimentum mauris consequat scelerisque.",
            "Cras ac nisl ultrices, scelerisque tellus vitae, rhoncus diam. Duis rhoncus pulvinar lorem et vehicula. Nulla in eros turpis. Duis sagittis lorem in feugiat sollicitudin. Aliquam hendrerit nisi fringilla, dapibus ante et, imperdiet magna. Curabitur vestibulum imperdiet orci ut sagittis. Vivamus vehicula nisi eleifend, malesuada tellus quis, venenatis diam. Curabitur vel consequat velit, sit amet condimentum dui. Phasellus sed velit consectetur, dictum purus vitae, semper felis. Donec in viverra nisi. Cras pulvinar arcu ut velit iaculis porta. Nulla a ullamcorper tellus. Quisque tellus nisl, ullamcorper sit amet ornare sed, placerat commodo sapien.",
            "Quisque facilisis laoreet interdum. Nulla et consectetur ligula, ac tristique massa. Maecenas convallis pulvinar quam, in varius dui blandit a. Vestibulum rhoncus luctus tortor eu eleifend. Sed justo metus, laoreet vel convallis nec, pharetra eu diam. Duis sit amet elit quis est facilisis vulputate eu eu sem. Donec porta orci sed accumsan eleifend. Donec non nisi sed nulla faucibus ornare non ac mauris. In hac habitasse platea dictumst. Aliquam sit amet dui at felis feugiat ullamcorper at eget erat. Sed ut semper dolor. Nullam facilisis nibh sem, quis congue sapien accumsan sed."
        ]
    },
};

export default articles;