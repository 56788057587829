import {LuAlarmClock} from "react-icons/lu";
import {FaRegUserCircle} from "react-icons/fa";
import articles from "../../components/articles/articles";
import {useNavigate} from "react-router-dom";

export default function Blog() {

    const navigate = useNavigate();

    const subscribe = (event) => {
        event.preventDefault();
        console.log("yep");
    }

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section className={"p-10 bg-white h-[600px] flex justify-center items-end"}>
                <div className={"flex w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full lg:w-2/5"}>
                        <h1 className={"font-main-bold text-3xl md:text-6xl pb-5"}>Project management tips & trends, delivered.</h1>
                        <p>
                            Alatura-te unei aventuri prin lumea transportului organizata de IA.
                        </p>
                        <form onClick={subscribe} className={"w-full"}>
                            <p className={"text-sm text-main-500 font-main-medium mt-5"}>
                                Aboneaza-te la newsletter
                            </p>
                            <div className={"flex flex-col md:flex-row gap-3"}>
                                <input type={"email"} className={"outline-none p-2 md:py-0 w-full border border-main-500 rounded-lg"}/>
                                <button className={"bg-main-500 w-full md:w-52 rounded-lg p-3 text-white"}>
                                    Aboneaza-te
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={"w-full hidden lg:block"}>
                        <img src={"https://clickup.com/blog/wp-content/uploads/2024/07/Frame-4-2.png"} className={"rounded-xl object-cover w-full h-[400px]"} alt={""}/>
                    </div>
                </div>
            </section>
            <section className={"p-10 flex justify-center"}>
                <div className={"flex flex-wrap gap-10 w-full max-w-[1150px]"}>
                    {
                        Object.keys(articles).map((slug, i) =>
                            <div
                                onClick={() => navigate("/blog/" + slug)}
                                className={"shadow-lg bg-white cursor-pointer w-full md:w-[calc(50%-40px)] xl:w-[calc(33.333%-40px)] hover:shadow-2xl transition-all duration-300 rounded-lg"}
                                key={i}>
                                <img
                                    src={articles[slug].image}
                                    alt={"img"} className={"rounded-t-xl object-cover h-[250px] w-full"}/>
                                <div className={"p-4"}>
                                    <p className={"w-fit py-1 px-2 rounded-full bg-main-500 text-xs text-white font-main-medium my-4"}>{articles[slug].tag}</p>
                                    <p className={"text-2xl pb-4 font-main-bold"}>{articles[slug].title}</p>
                                    <div className={"flex justify-between"}>
                                        <div className={"flex gap-1.5 items-center"}>
                                            <FaRegUserCircle className={"text-main-500"}/>
                                            <span>{articles[slug].personName}</span>
                                        </div>
                                        <div className={"flex gap-2 items-center"}>
                                            <LuAlarmClock className={"text-main-500"}/>
                                            <span>{articles[slug].estimate}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>

        </div>
    );
};