import Header from "../../components/header/Header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "../home/Home";
import MainAppRouter from "../../router/MainAppRouter";
import Footer from "../../components/footer/Footer";
import ContactPage from "../contact/Contact";
import Pricing from "../pricing/Pricing";
import Blog from "../blog/Blog";
import BlogArticle from "../blog/BlogArticle";
import TermsAndConditions from "../terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "../terms-and-conditions/PrivacyPolicy";
import CookiePolicy from "../terms-and-conditions/CookiePolicy";
import AiDocumentCheckerPresentation from "../products/AiDocumentCheckerPresentation";

function App() {
    return (
        <div className={"font-main-light"}>
            <Header/>
            <BrowserRouter>
                <Routes>
                    <Route path={MainAppRouter.home} element={<Home/>}/>
                    <Route path={MainAppRouter.blog} element={<Blog/>}/>
                    <Route path={MainAppRouter.blogArticle} element={<BlogArticle/>}/>
                    <Route path={MainAppRouter.pricing} element={<Pricing/>}/>
                    <Route path={MainAppRouter.contact} element={<ContactPage/>}/>
                    <Route path={MainAppRouter.termsAndConditions} element={<TermsAndConditions/>}/>
                    <Route path={MainAppRouter.documentCheckerAi} element={<AiDocumentCheckerPresentation />}/>
                    <Route path={MainAppRouter.privacyPolicy} element={<PrivacyPolicy/>}/>
                    <Route path={MainAppRouter.cookiePolicy} element={<CookiePolicy/>}/>
                </Routes>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;
